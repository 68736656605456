import { LatLngModel } from "../maps/latLngModel";

export class SurgaMapProperty {
  public AltWebUrl: string = "";
  public ListingType: string = "";
  public DisplayAddress: boolean = true;
  public ImagesId: string = "";
  public Latitude: number = 0;
  public Longitude: number = 0;
  public SiteName: string = "";
  public StateName: string = "";
  public StreetName: string = "";
  public StreetNumber: string = "";
  public SuburbName: string = "";
  public SurgaWebListingId: string = "";
  public Title: string = "";
  [key: string]: any;

  constructor(property: Record<string, any>) {
    Object.keys(this).forEach((key) => {
      const parameterName = key.charAt(0).toLowerCase() + key.slice(1);
      if (property[parameterName] != null) {
        this[key] = property[parameterName];
      }
    });
  }
  public getAddressTitle(): string {
    let addressTitle = "";
    if (this.SiteName !== "") {
      addressTitle += ", " + this.SiteName;
    }
    if (this.Title !== "") {
      addressTitle += ", " + this.Title;
    }
    addressTitle += ", " + this.StreetNumber + " " + this.StreetName;
    if (this.SuburbName !== "") {
      addressTitle += ", " + this.SuburbName;
    } else if (this.StateName !== "") {
      addressTitle += ", " + this.StateName;
    }
    addressTitle = addressTitle.substring(1);
    return addressTitle.trim();
  }
  public getAddressString(): string {
    let returnedAddress: string = "";
    returnedAddress += this.addSegment(this.SiteName);
    returnedAddress += this.addSegment(this.Title);
    returnedAddress += this.addSegment(this.StreetNumber);
    returnedAddress += this.addSegment(this.StreetName);
    returnedAddress += this.addSegment(this.SuburbName);
    returnedAddress += this.addSegment(this.StateName);
    if (returnedAddress.length > 0) {
      returnedAddress = returnedAddress.slice(0, -1);
    }
    return returnedAddress;
  }

  private addSegment(segment: string) {
    if (segment && segment != "") {
      segment = segment.replaceAll(/[^a-zA-Z0-9]/g, "-") + "-";
      while (segment.includes("--")) {
        segment = segment.replaceAll("--", "-");
      }
      return segment;
    } else {
      return "";
    }
  }

  public getLatLng(): LatLngModel {
    let coordinates: LatLngModel = new LatLngModel(this.Latitude, this.Longitude);
    return coordinates
  }
}

const locationSettingsElement =
  document.getElementsByTagName("location-settings")[0];
function location() {
  // returns whether page is initiated as a location page.
  function isLocation() {
    if (locationSettingsElement) {
      return true;
    }
    return false;
  }
  // initiates locale module
  function getParameters() {
    if (locationSettingsElement) {
      return locationSettingsElement.getAttribute("location-parameters");
    }
  }
  function getTitle(): string {
    if (locationSettingsElement) {
      return locationSettingsElement.getAttribute("location-title");
    }
  }
  function getDescription(): string {
    if (locationSettingsElement) {
      return locationSettingsElement.getAttribute("location-description");
    }
  }
  return { getParameters, getDescription, getTitle, isLocation };
}
export { location };

import { RecommendedSearchModel } from "./recommendedSearchModel";
import { SearchFieldsModel } from "./searchFieldsModel";

//stores data required by search function.
export class SearchDataModel {
  // search text inputted by user.
  public searchText: string;
  public recommendedSearch: RecommendedSearchModel;
  public rawRegionObject: Record<string, string>[];
  public rawSuburbObject: Record<string, string>[];
  public rawAddressObject: Record<string, string>[];
  public setupProgress = "";
  // suburb / address fields of all items in asset file.
  public allAddressData: SearchFieldsModel;
  [key: string]: any;
  constructor() {
    this.searchText = "";
    this.recommendedSearch = new RecommendedSearchModel();
    this.allAddressData = new SearchFieldsModel();
    this.rawRegionObject = [];
    this.rawSuburbObject = [];
    this.rawAddressObject = [];
    this.setupProgress = "initiated";
  }

  public resetRecommendedSearch() {
    this.recommendedSearch = new RecommendedSearchModel();
  }

  public JSONcopy(jsobject: any): void {
    this.searchText = jsobject.searchText;
    this.recommendedSearch.JSONcopy(jsobject.recommendedSearch);
    this.allAddressData.JSONcopy(jsobject.allAddressData);
    this.rawRegionObject = jsobject.rawRegionObject;
    this.rawSuburbObject = jsobject.rawSuburbObject;
    this.rawAddressObject = jsobject.rawAddressObject;
    if (jsobject.setupProgress !== "complete") {
      this.setupProgress = "start";
    }
  }
  // find suburbs in search text and add to sortedaddressdata
  public sortSuburbs(): (string | number)[] {
    const returnedArray = this.sortByParameter("suburbs");
    returnedArray[0] = (returnedArray[0] as number) * 1.0;
    return returnedArray;
  }
  // find streets in search text and add to sortedaddressdata
  public sortStreets(): (string | number)[] {
    const returnedArray = this.sortByParameter("streets");
    returnedArray[0] = (returnedArray[0] as number) * 1.15;
    return returnedArray;
  }
  // find postcode in search text and add to sortedaddressdata
  public sortPostcodes(): (string | number)[] {
    const returnedArray = this.sortByParameter("postcodes");
    returnedArray[0] = (returnedArray[0] as number) * 1.05;
    return returnedArray;
  }
  // find state in search text and add to sortedaddressdata
  public sortState(): (string | number)[] {
    const returnedArray = this.sortByParameter("states");
    returnedArray[0] = (returnedArray[0] as number) * 1.1;
    return returnedArray;
  }
  // find sitename in search text and add to sortedaddressdata
  public sortSiteName(): (string | number)[] {
    const returnedArray = this.sortByParameter("siteName");
    returnedArray[0] = (returnedArray[0] as number) * 1.2;
    return returnedArray;
  }
  public sortByParameter(parameter: string): (string | number)[] {
    let matchedWords: number = 0;
    let matchedValue: string = "";
    for (let child of this.allAddressData[parameter] as string[]) {
      let childWordNo: number = child.split(" ").length;
      if (
        this.searchText.toLowerCase().indexOf(child) !== -1 &&
        (childWordNo > matchedWords ||
          (childWordNo === matchedWords && child.length > matchedValue.length))
      ) {
        matchedWords = child.split(" ").length;
        matchedValue = child;
      }
    }
    return [matchedWords, matchedValue];
  }
  public setSearchText(searchText: string): void {
    // set search text.
    this.searchText = searchText;
  }
  public clearRawObjects(): void {
    this.rawRegionObject = [];
    this.rawSuburbObject = [];
    this.rawAddressObject = [];
  }
}

export class SurgaProperty {
  public AgencyId: string = "";
  public Agents: string = "";
  public AltWebURL: string = "";
  public AuctionComment: string = "";
  public BldAreaUOM: string = "sqm";
  public BookInspectionURL: string = "";
  public Count: number = 0;
  public DisplayAddress: boolean = true;
  public DisplayPrice: boolean = true;
  public DisplayRent: boolean = true;
  public DocumentsDetails: string = "";
  public EnergyRating: string = "";
  public EPCRating: string = "";
  public Features: string = "";
  public GroupListing: boolean = false;
  public ImagesId: string = "";
  public LandArea: number = 0;
  public LandAreaConverted: number = 0;
  public LandAreaUOM: string = "sqm";
  public Latitude: number = 0;
  public LeaseBrochureOutgoings: string = "";
  public LeaseBrochureOption: string = "";
  public LeaseBrochureComments: string = "";
  public LeaseCarSpace: number = 0;
  public LeaseNetGross: string = "Net";
  public ListingTable: ListingUnits[] = [];
  public ListingType: string = "";
  public ListingSheetDocName: string = "";
  public LocationDesc: string = "";
  public Longitude: number = 0;
  public PostCode: string = "";
  public PriceMax: number = 0;
  public PriceMin: number = 0;
  public PropDesc: string = "";
  public PropertyType: string = "";
  public Region: string = "";
  public RentBldAreaMax: number = 0;
  public RentBldAreaMin: number = 0;
  public RentMax: number = 0;
  public RentMin: number = 0;
  public RentPeriod: string = "Annual";
  public SaleBrochureListPrice: string = "";
  public SaleBrochureOutgoings: string = "";
  public SaleBrochureComment: string = "";
  public SaleBldAreaMax: number = 0;
  public SaleBldAreaMin: number = 0;
  public SaleCarSpace: number = 0;
  public SaleDate: string = "";
  public ShowInPlaceofPrice: string = "";
  public ShowInPlaceofRent: string = "";
  public SiteName: string = "";
  public StateName: string = "";
  public Strata: boolean = false;
  public StreetName: string = "";
  public StreetNumber: string = "";
  public SuburbName: string = "";
  public SurgaPropertyId: string = "";
  public SurgaSiteId: string = "";
  public SurgaWebListingId: string = "";
  public Title: string = "";
  public UnderContract: boolean = false;
  public UnderOffer: boolean = false;
  public VirtualTours: string = "";
  public WebDescription: string = "";
  public WebHeader: string = "";
  public Zoning: string = "";
  public Videos: string = "";
  [key: string]: any;

  // values for internal site use.
  public _websiteListingType = "";

  constructor() {}

  public JSONCopy(property: Record<string, any>) {
    Object.keys(this).forEach((key) => {
      if (property[key] != null) {
        this[key] = property[key];
      }
    });
  }
  
  public GetYoutubeId(): string {
    if (this.Videos.length === 0 ) {
      return "";
    }
    const video = this.Videos.split("|")[1];
    var regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = video.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      "";
    }
  }
  public getAddressTitle(): string {
    let addressTitle = "";
    if (this.SiteName !== "") {
      addressTitle += ", " + this.SiteName;
    }
    if (this.Title !== "") {
      addressTitle += ", " + this.Title;
    }
    addressTitle += ", " + this.StreetNumber + " " + this.StreetName;
    if (this.SuburbName !== "") {
      addressTitle += ", " + this.SuburbName;
    } else if (this.StateName !== "") {
      addressTitle += ", " + this.StateName;
    }
    addressTitle = addressTitle.substring(1);
    return addressTitle.trim();
  }
  public getAddressString(): string {
    let returnedAddress: string = "";
    returnedAddress += this.addSegment(this.SiteName);
    returnedAddress += this.addSegment(this.Title);
    returnedAddress += this.addSegment(this.StreetNumber);
    returnedAddress += this.addSegment(this.StreetName);
    returnedAddress += this.addSegment(this.SuburbName);
    returnedAddress += this.addSegment(this.StateName);
    if (returnedAddress.length > 0) {
      returnedAddress = returnedAddress.slice(0, -1);
    }
    return returnedAddress;
  }

  private addSegment(segment: string) {
    if (segment && segment != "") {
      segment = segment.replaceAll(/[^a-zA-Z0-9]/g, "-") + "-";
      while (segment.includes("--")) {
        segment = segment.replaceAll("--", "-");
      }
      return segment;
    } else {
      return "";
    }
  }

  public setupListing(property: Record<string, any>) {
    Object.keys(this).forEach((key) => {
      if (property[key] != null) {
        this[key] = property[key];
      }
    });
    this.ImagesId = this.removeMap(this.ImagesId);
    this.setupListingTable(property.ListingUnits);
    this.roundPrices();
    this.setupSqft();
    this.setupAddressNotDisplayed();
    this.setupAuctionComment(property);
  }

  private setupAuctionComment(property: Record<string, any>) {
    try {
      if (property["SaleMComm"] != null) {
        this.AuctionComment = property["SaleMComm"];
      } else {
        this.AuctionComment = (property["ListingUnits"][0])["SaleMComm"];
      }
    } catch(ex) {

    }
  }

  private setupListingTable(listingUnits: Record<string, any>[]) {
    if (!listingUnits) {
      return;
    }
    for (const listingUnit of listingUnits) {
      this.ListingTable.push(new ListingUnits(listingUnit));
    }
  }

  private setupAddressNotDisplayed() {
    if (!this.DisplayAddress) {
      this.Longitude = 0;
      this.Latitude = 0;
      this.SiteName = "";
      this.StreetName = "";
      this.StreetNumber = "";
    }
  }

  private roundPrices() {
    this.PriceMax = Math.round(this.PriceMax);
    this.PriceMin = Math.round(this.PriceMin);
    this.RentMax = Math.round(this.RentMax);
    this.RentMin = Math.round(this.RentMin);
  }

  private setupSqft() {
    if (this.LandAreaUOM === "sqft") {
      this.LandAreaUOM = "sq. ft.";
    }
    if (this.BldAreaUOM === "sqft") {
      this.BldAreaUOM = "sq. ft.";
    }
  }

  private removeMap(imageString: string): string {
    let imageList: string[] = imageString.split(",");
    let imageWithoutMap: string = "";
    imageList.forEach((value) => {
      if (value.split("|")[0].toLowerCase() !== "map") {
        imageWithoutMap = imageWithoutMap + "," + value;
      }
    });
    if (imageWithoutMap.length > 0) {
      imageWithoutMap = imageWithoutMap.substring(1);
    }
    return imageWithoutMap;
  }

  public setupFeatured(property: any) {
    this.DisplayAddress = property.Address.DisplayInListing;
    if (property.Address.RegionName !== undefined) {
      this.Region = property.Address.RegionName._parameter;
    }
    if (property.Address.postcode !== undefined) {
      this.PostCode = property.Address.postcode._parameter;
    }
    if (property.Address.sitename !== undefined) {
      this.SiteName = property.Address.sitename._parameter;
    }
    if (property.Address.state !== undefined) {
      this.StateName = property.Address.state._parameter;
    }
    if (property.Address.streetname !== undefined) {
      this.StreetName = property.Address.streetname._parameter;
    }
    if (property.Address.streetnumber !== undefined) {
      this.StreetNumber = property.Address.streetnumber._parameter;
    }
    if (property.Address.suburb !== undefined) {
      this.SuburbName = property.Address.suburb._parameter;
    }
    if (property.Description !== undefined) {
      this.WebDescription = property.Description._parameter;
    }
    if (property.Heading !== undefined) {
      this.WebHeader = property.Heading._parameter;
    }
    if (property.ListingType !== undefined) {
      switch (property.ListingType._parameter) {
        case "Lease":
          this.ListingType = "L";
          break;
        case "Sale":
          this.ListingType = "V";
          break;
        case "Sale/Lease":
          this.ListingType = "LV";
          break;
        default:
          this.ListingType = "I";
      }
    }
    if (property.SurgaWebID !== undefined) {
      this.SurgaWebListingId = property.SurgaWebID;
    }
    if (property.AltWebUrl !== undefined) {
      this.AltWebURL = property.AltWebUrl._parameter;
    }
    if (property.Images.Image !== undefined) {
      let images: string = "";
      try {
        for (const propertyImage of property.Images.Image) {
          let comma = "";
          if (images.length !== 0) {
            comma = ",";
          }
          images +=
            comma + propertyImage.imageref + "||" + propertyImage._parameter;
        }
      } catch (ex) {
        images =
          property.Images.Image.imageref +
          "||" +
          property.Images.Image._parameter;
      }
      this.ImagesId = images;
    }
    this.setupSqft();
    this.roundPrices();
  }
}

class ListingUnits {
  public AreaComponents: string = "";
  public AskingPrice: number = 0;
  public AskingRent: number = 0;
  public BldArea: number = 0;
  public BldDivArea: string = "";
  public IncomePeriod: string = "";
  public LeaseAvailability: string = "";
  public LeaseCarSpaces: number = 0;
  public LeaseOutgoings: number = 0;
  public LeaseNetGross: string = "";
  public ListingTitle: string = "";
  public ListingType: string = "";
  public PricePerUnit: number = 0;
  public RentPerUnit: number = 0;
  public RentPeriod: string = "";
  public RentTaxType: string = "";
  public SaleAvailability: string = "";
  public SaleCarSpace: number = 0;
  public SaleMethod: string = "";
  public SaleOutgoings: number = 0;
  public SaleTaxAmount: number = 0;
  public SaleTaxType: string = "";

  constructor(property: any) {
    Object.keys(this).forEach((key) => {
      if (property[key] != null) {
        this[key] = property[key];
      }
    });
    this.roundPrices();
  }

  private roundPrices() {
    this.AskingPrice = Math.round(this.AskingPrice);
    this.AskingRent = Math.round(this.AskingRent);
    this.PricePerUnit = Math.round(this.PricePerUnit);
    this.RentPerUnit = Math.round(this.RentPerUnit);
    this.SaleOutgoings = Math.round(this.SaleOutgoings);
    this.SaleTaxAmount = Math.round(this.SaleTaxAmount);
  }
}

/**
 * Manages the search displays of Surga components.
 * Includes calculations of recommended properties and min/max price and min/max land areas.
 */
import { reactive, ref } from "vue";
import { parameterHelperComp } from "../parameterHelperComp";
import { SearchFilters } from "./../models/searchFiltersModel";
import { SearchOptions } from "./../models/searchOptionsModel";
import { stateStorage } from "./../stateStorageComp";

// flag utilised to detect changes made to the property options. When value changes, changes will be triggered in related modules.
// increment value when property search options changes.
const state = reactive({ searchOptions: new SearchOptions() });
const searchTrigger = ref(0);
let initiated: boolean = false;
let displayParameters: boolean = false;

const propertyOptionsToken = "properties-options";

function surgaSearchOptions() {
  // Utilised once in the initiator component. If object does not exist, create new in local storage.
  function initiate() {
    // only run when module is uninitiated.
    if (!initiated) {
      let newSearch: SearchOptions = new SearchOptions();
      if (!stateStorage(propertyOptionsToken).exists().value) {
        setSearchOptions(newSearch);
      } else {
        newSearch.cast(stateStorage(propertyOptionsToken).get().value);
        setSearchOptions(newSearch);
      }
      // set values from URL(if applicable)
      newSearch.castParameters(parameterHelperComp().getParameters());
      initiated = true;
    }
  }

  function clearSettings(): void {
    let newSearch: SearchOptions = new SearchOptions();
    setSearchOptions(newSearch);
    resetParameters();
  }

  // enables and initiates parameters for search function.
  // if parameters are enabled, parameters will be visible on URL.
  function enableParameters() {
    if (!displayParameters) {
      displayParameters = true;
      resetParameters();
    }
  }

  // when used, will trigger search commands to all listing modules.
  function triggerSearch() {
    searchTrigger.value++;
  }

  function get() {
    function searchOptionsReactive() {
      return state;
    }
    function searchTriggerReactive() {
      return searchTrigger;
    }
    return { searchOptionsReactive, searchTriggerReactive };
  }

  function set() {
    function listingType(type: string) {
      setProperty(type, "activeListingType");
      resetParameters();
    }
    function searchText(search: string) {
      setProperty(search, "searchText");
      resetParameters();
    }
    function sortOrder(isAscending: boolean) {
      setProperty(isAscending, "isAscending");
      resetParameters();
    }
    function sortProperty(property: string) {
      setProperty(property, "sortProperty");
      resetParameters();
    }
    function filters() {
      function resetAll() {
        const newOptions: SearchOptions = state.searchOptions;
        newOptions.filters = new SearchFilters();
        setSearchOptions(newOptions);
      }
      function minPrice(price: number) {
        setPropertyFilters(price, "minPrice");
        resetParameters();
      }
      function maxPrice(price: number) {
        setPropertyFilters(price, "maxPrice");
        resetParameters();
      }
      function minArea(price: number) {
        setPropertyFilters(price, "minArea");
        resetParameters();
      }
      function maxArea(price: number) {
        setPropertyFilters(price, "maxArea");
        resetParameters();
      }
      function propertyType(propertyType: string) {
        setPropertyFilters(propertyType, "propertyType");
        resetParameters();
      }
      return { minPrice, maxPrice, minArea, maxArea, propertyType, resetAll };
    }
    function totalCount(count: number) {
      setProperty(count, "totalCount");
    }
    function sort(property: string, isAscending: boolean) {
      const newOptions: SearchOptions = state.searchOptions;
      newOptions.sortProperty = property;
      newOptions.isAscending = isAscending;
      setSearchOptions(newOptions);
      resetParameters();
    }
    return {
      listingType,
      searchText,
      sortOrder,
      sortProperty,
      filters,
      sort,
      totalCount
    };
    // private functions
    // sets properties.
    function setProperty(newValue: any, property: string) {
      const newOptions: SearchOptions = state.searchOptions;
      newOptions[property] = newValue;
      setSearchOptions(newOptions);
    }

    function setPropertyFilters(newValue: any, property: string) {
      const newOptions: SearchOptions = state.searchOptions;
      newOptions.filters[property] = newValue;
      setSearchOptions(newOptions);
    }
  }
  return { set, get, initiate, triggerSearch, enableParameters, clearSettings };

  // private functions
  // sets search Option object in both local storage and global object.
  function setSearchOptions(storeOptions: SearchOptions) {
    stateStorage(propertyOptionsToken).set(storeOptions);
    state.searchOptions = storeOptions;
  }

  // retrieves and sets new parameters.
  function resetParameters() {
    if (displayParameters) {
      const parameters: (
        | Record<string, string | boolean | number>
        | Array<string>
      )[] = state.searchOptions.getParameterChanges();
      parameterHelperComp().resetParameters();
      parameterHelperComp().setParameters(parameters[0]);
      parameterHelperComp().deleteParameters(parameters[1] as string[]);
      parameterHelperComp().pushParameters();
    }
  }
}

export { surgaSearchOptions };

import { location } from "../ts/locationComp";
let queryString: string;
let paramManager: URLSearchParams;
function parameterHelperComp() {
  function resetParameters() {
    queryString = window.location.search;
    paramManager = new URLSearchParams(queryString);
  }
  function setParameters(parameters: Record<string, any>): void {
    for (let key in parameters) {
      paramManager.set(key, parameters[key]);
    }
  }

  function deleteParameters(parameters: string[]): void {
    for (let parameterName of parameters) {
      paramManager.delete(parameterName);
    }
  }

  function getParameters(): Record<string, any> {
    let pQueryString: string = "";
    // if it is a location page, initiate location based on location value, otherwise load search values.
    if (location().isLocation()) {
      pQueryString = location().getParameters();
    } else {
      pQueryString = window.location.search;
    }
    const pParamManager = new URLSearchParams(pQueryString);
    let result: Record<string, any> = {};
    pParamManager.forEach(function (value, key) {
      result[key] = value.replaceAll("#", "");
    });
    return result;
  }

  function pushParameters() {
    window.history.replaceState(null, null, "?" + paramManager.toString());
  }
  return {
    resetParameters,
    setParameters,
    deleteParameters,
    pushParameters,
    getParameters
  };
}
export { parameterHelperComp };
